app.controller('TransactionController', ['$scope', '$log', '$mdDialog', function ($scope, $log, $mdDialog) {

    $scope.submitTransaction = function() {
        if ($scope.transactionForm.$pristine || !$scope.transactionForm.$valid) {
            return;
        }
        $scope.transactionForm.$submitted = true;
        $('[name="transactionForm"]').submit();
    };

}]);
