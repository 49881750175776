app.controller('PaymentController', ['$scope', '$log', '$mdDialog', function ($scope, $log, $mdDialog) {


    function stripeResponseHandler (status, response) {
        var error = $mdDialog.alert()
            .title('Error Processing Payment')
            .htmlContent('It looks like there\'s a problem with your billing information.<br/>Please check your billing information and try again.')
            .ariaLabel('error occurred')
            .ok('OK');

        if (response.error) {
            error.htmlContent(response.error.message);
            $mdDialog.show(error);
            $scope.paymentForm.$submitted = false;
        } else {
            $('[name="stripe-token"]').val(response.id);
            if ($scope.paymentForm.$pristine || !$scope.paymentForm.$valid) {
                return;
            }
            $('[name="paymentForm"]').submit();
        }
    }

    $scope.submitPayment = function () {
        $scope.paymentForm.$submitted = true;
        Stripe.card.createToken({
            number:         $scope.cc,
            cvc:            $scope.cvc,
            exp_month:      $scope.ccmonth,
            exp_year:       $scope.ccyear
        }, stripeResponseHandler);
    };

    Mousetrap($('[name="paymentForm"]')[0]).bind('enter', function() {
        $scope.submit();
    });
}]);
